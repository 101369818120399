import React from 'react';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const params = useParams();

  useMountEffect(() => {
    executeApp();
    setTimeout(moveToDownload, 2000);
  });

  const moveToDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      moveToAppStore();
    } else if (/android/i.test(userAgent)) {
      moveToPlayStore();
    }
  };

  const moveToAppStore = (e) => {
    window.location.replace('itms-apps://apps.apple.com/kr/app/클럽아티제-아티제-멤버십/id1437734675');
  };

  const moveToPlayStore = (e) => {
    window.location.replace('market://details?id=com.clubartisee');
  };

  const executeApp = () => {
    const param = params['*'];

    // 쿠폰 등록
    if (param.startsWith('coupon-pin')) {
      window.location.replace('clubartisee://coupon-pin');
    }
    // 포인트 등록
    else if (param.startsWith('point-pin')) {
      window.location.replace('clubartisee://point-pin');
    }
    // 임직원 인증
    else if (param.startsWith('employee-cert')) {
      window.location.replace('clubartisee://employee-cert');
    }
    // 배달오더
    else if (param.startsWith('order/delivery')) {
      window.location.replace('clubartisee://order/delivery');
    }
    // 픽업오더 매장내역
    else if (param.startsWith('order/pickup/shops')) {
      window.location.replace('clubartisee://order/pickup/shops');
    }
    // 예약오더 매장내역
    else if (param.startsWith('order/reserve/shops')) {
      window.location.replace('clubartisee://order/reserve/shops');
    }
    // 앱 실행
    else {
      window.location.replace('clubartisee://launch');
    }
  };

  return (
    <div className={cx('container')}>
      <img
        src="https://play-lh.googleusercontent.com/kHJoyJbZ0mmYxUGrgq61KiZpBTM66ynnpToejqHtXfzlgeyP3id_jRzdmZ5Lt8Cfcw=w480-h960-rw"
        className={cx('icon')}
        alt=""
      />
      <h1>클럽아티제 - 아티제 멤버십</h1>
      <p>
        지금 아티제 멤버십 앱을 다운로드 하시고,
        <br />
        다양한 멤버십 혜택의 기회를 잡으세요!
      </p>
      <div className={cx('buttons')}>
        <img
          className={cx('button')}
          src={require('../../assets/images/appstore.png')}
          onClick={moveToAppStore}
          alt=""
        />
        <img
          className={cx('button')}
          src={require('../../assets/images/playstore.png')}
          onClick={moveToPlayStore}
          alt=""
        />
      </div>
    </div>
  );
};

export default Page;
