import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Link from './pages/link';
import PrivacyPolicy from './pages/agreements/privacy-policy';
import TermsOfUse from './pages/agreements/terms-of-use';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/link/*" element={<Link />} />

        <Route path="agreements">
          <Route exact path="privacy-policy.html" element={<PrivacyPolicy />} />
          <Route exact path="terms-of-use.html" element={<TermsOfUse />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
