export default (
  <div>
    <p>㈜보나비의 클럽아티제 서비스 이용과 관련하여 필요한 사항을 규정합니다.</p>
    <div>
      <h1>제 1 장 총칙</h1>
      <h2>제 1 조 (목적)</h2>
      <p>
        이 약관은 주식회사 보나비(이하 “회사”라 합니다)가 발행한 클럽아티제 카드를 구입 또는 정당한 방법으로 소지한
        고객이 회사가 제공하는 클럽아티제 서비스를 이용함에 있어 필요한 이용 조건, 절차 및 당사자간의 권리, 의무 등
        기본적인 사항을 규정하는 것을 목적으로 합니다.
      </p>

      <h2>제 2 조 (용어의 정의)</h2>
      <p>이 약관에서 사용하는 용어는 다음과 같이 정의합니다.</p>
      <ul>
        <li>
          1. “클럽아티제 카드”란 회사가 정의한 기술사양에 따라 회사가 인증한 Chip 또는 Application을 내장하고 클럽아티제
          카드 브랜드를 부착한 선불전자지급수단임과 동시에 고객이 “포인트”를 적립하여 서비스를 정상적으로 이용할 수
          있도록 회사가 승인한 카드로서 회사가 발급합니다.
        </li>
        <li>2. “고객”이란 이 약관에 동의하고 매장에서 클럽아티제 카드 충전한 자를 말합니다.</li>
        <li>
          3. “회원”이란 고객 중 회사의 클럽아티제 홈페이지 등을 통해 이용 약관과, 개인정보의 수집, 제공 및 활용에 관한
          동의서에 동의하고, 소지한 클럽아티제 카드를 등록한 자를 말합니다.
        </li>
        <li>4. “매장”이란 회사가 직영으로 운영하는 모든 매장을 말합니다.</li>
        <li>5. “최초 충전 (Activation)” : 클럽아티제 카드를 처음 구입하여 충전 하는 행위를 말합니다.</li>
        <li>6. “결제 (Redemption)” : 클럽아티제 카드로 이용금액의 전체 또는 일부 금액을 결제 하는 행위를 말합니다.</li>
        <li>7. “잔액 조회 (Balance inquiry)” : 클럽아티제 카드에 대한 잔액에 대해 문의하는 행위를 말합니다.</li>
        <li>8. “재충전 (Reload)” : 소지한 클럽아티제 카드에 금액을 재충전 하는 행위를 말합니다.</li>
        <li>
          9. “환급”이란 클럽아티제 카드에 기록된 잔액을 고객과 회사간에 약정된 방법과 절차에 따라 고객에게 돌려주는 것을
          말하며, 동일한 의미로서 “환불”이란 용어로 사용하기도 합니다.
        </li>
        <li>10. “고장카드”란 정상적으로 사용이 불가능한 클럽아티제 카드를 말하며, 불량카드와 파손카드로 구분합니다.</li>
        <ul>
          <li>
            가. 고장카드 중 “불량카드”란 클럽아티제 카드의 외형상 이상은 없으나, 기능상의 문제로 단말기 등에서 사용이
            불가능한 상태의 클럽아티제 카드를 말합니다.
          </li>
          <li>
            나. 고장카드 중 “파손카드”란 고객의 고의 혹은 과실로 구멍 뚫림, 구김, 휘어짐, 찍힘, 태움, 조각남, 깨짐,
            갈라짐, 카드 번호 지워짐 등으로 인하여 훼손된 클럽아티제 카드를 말합니다.
          </li>
        </ul>
        <li>11. “포인트”란 고객이 회사의 상품을 구입할 경우 회사가 고지한 방침에 따라 부여되는 혜택을 말합니다.</li>
        <li>
          12. “포인트 부정 적립 및 사용”이란 고객이 제품 및 상품을 구매 또는 사용하지 않았음에도 불구하고 해당 고객에게
          “포인트”가 적립 또는 사용된 경우를 말합니다.
        </li>
        <li>
          13. “잔액보호”란 클럽아티제 카드를 홈페이지에 등록한 회원이 분실 신고를 할 경우, 분실신고 당시의 카드 잔액을
          보호해 주는 것을 말합니다.
        </li>
        <li>
          14. “스탬프”란 회사에서 프로모션을 진행하면 등록된 클럽아티제 카드 결제를 통해 스탬프를 적립하여 관련 혜택을
          받을 수 있습니다.
        </li>
      </ul>

      <h2>제 3 조 (약관의 효력 및 변경 등)</h2>
      <ul>
        <li>
          1. 본 약관은 그 내용을 회사가 홈페이지에 게시하고, 고객이 클럽아티제 카드를 충전 후 소지 또는 기타 정당한
          방법으로 소지하여 사용함으로써 그 효력이 발생됩니다.
        </li>
        <li>
          2. 본 약관은 수시로 변경될 수 있으며 회사가 약관을 변경하고자 할 경우에는 개정된 약관을 적용하고자 하는
          날(이하 “효력 발생일”)로부터 1개월 전에 약관이 개정된다는 사실과 개정된 내용 등을 홈페이지 내 게시하는 방법
          등으로 관련 법령에 의거하여 고객에게 고지합니다. 다만, 법령의 개정이나 제도의 개선, 감독기관의 권고 등으로
          인하여 긴급히 이 약관을 변경할 경우에는 즉시 이를 게시합니다.
        </li>
        <li>
          3. 본 조의 규정에 의하여 개정된 약관은 원칙적으로 그 변경되는 약관의 효력 발생일로부터 장래를 향하여
          유효합니다.
        </li>
        <li>
          4. 고객이 변경된 약관 사항에 동의하지 않을 경우, 약관의 효력 발생 전일까지 서비스 이용을 중단하거나 회원탈퇴
          및 등록된 클럽아티제 카드를 고객이 홈페이지에서 등록 해지함으로써 이용 계약을 해지할 수 있으며, 약관의 개정과
          관련하여 효력 발생일 전일까지 이의를 제기하지 않는 경우에는 개정된 약관에 동의한 것으로 간주합니다.
        </li>
      </ul>

      <h2>제 4 조 (약관 외 준칙)</h2>
      <p>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계 법령 또는 상 관례에 따릅니다.</p>

      <h1>제 2 장 클럽아티제 카드 서비스</h1>
      <h2>제 5 조 (목적포인트 이용)</h2>
      <ul>
        <li>
          1. 선불전자지급수단으로의 클럽아티제 카드
          <ul>
            <li>
              가. 고객은 국내 아티제 매장 어디에서나 클럽아티제 카드로 서비스를 제공받거나 제품 및 상품을 구매할 수
              있습니다. 단, 클럽아티제 카드의 발행 목적, 매장의 임대차 계약상 임대인이 요구한 조건, 또는 기술적
              사유(시스템 점검, 단말기 고장, 통신회선 불량, 신규 카드 또는 단말기의 안정화 작업 등)로 일부 클럽아티제
              카드는 매장 내 이용이 제한될 수 있으며, 이 경우 회사는 매장에 배포된 안내장 또는 홈페이지 등을 이용하여
              고객에게 고지합니다.
            </li>
            <li>
              나. 클럽아티제 카드로 매장에서 제조한 음료를 구입하는 경우 샷 추가 등과 같은 사전 공지된 혜택을 받으실 수
              있습니다. 다만, 이 혜택은 음료 1잔 구매 시 1회에 한하여 제공되며, 구체적인 혜택 사항은 회사의 마케팅
              정책에 따라 변경될 수 있습니다.
            </li>
            <li>
              다. 본 클럽아티제 카드 는 기술적 결함이나 네트워크의 오류 및 장애 발생으로 일시적으로 서비스 이용에 제한이
              발생할 수 있습니다.
            </li>
            <li>라. 클럽아티제 카드 잔액에 대한 고객의 권리는 최종 충전일로부터 5년이 지나면 자동 소멸합니다.</li>
          </ul>
        </li>
        <li>
          2. 포인트 적립카드로의 클럽아티제 카드
          <ul>
            <li>
              가. 회원은 회사의 홈페이지에 클럽아티제 카드를 등록함으로써, 클럽아티제 카드의 혜택을 받을 수 있습니다.
            </li>
            <li>
              나. “포인트”적립 : 회원은 회사에서 제품 및 상품 구입을 통하여 “포인트”를 적립 받을 수 있습니다. 구체적인
              적립 방법과 적립 기준은 본 약관 제 11조의 “포인트” 적립 기준에 따라 제공됩니다.
            </li>
            <li>
              다. 기타 서비스 : 회사는 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다. 서비스 제공 기준은 각각의
              서비스 제공 시점에 회사 홈페이지에 고시한 기준으로 적용됩니다.
            </li>
            <li>
              라. 회원이 본 약관 또는 회사 개인정보취급방침에 따라 등록해야 하는 고객에 대한 정보를 등록하지 않거나
              허위로 등록하는 경우 본 조에 따른 혜택의 이용이 제한될 수 있습니다.
            </li>
          </ul>
        </li>
      </ul>

      <h2>제 6 조 (클럽아티제 회원가입)</h2>
      <ul>
        <li>1. 클럽아티제 카드는 회사의 전국 매장(일부 매장 제외)에서 발급 받으실 수 있습니다.</li>
        <li>
          2. 클럽아티제 회원이 되고자 하는 고객은 본 약관에 동의하고 회사가 발급한 클럽아티제 카드를 모바일 앱 또는
          홈페이지에 등록함으로써 클럽아티제 프로그램에 참여할 수 있습니다. 단, 14세 미만인 자는 회원 가입이 불가합니다.
        </li>
        <li>
          3. 고객으로부터 회원 가입신청이 있는 경우 회사는 자체 기준에 따른 심사를 거친 뒤 동 기준을 만족시키는 고객에게
          회원 자격 및 클럽아티제 혜택 이용을 승인하여 드립니다.
        </li>
        <li>4. 회원은 회원 자격을 타인에게 양도하거나 대여하거나 담보의 목적물로 이용할 수 없습니다.</li>
      </ul>

      <h2>제 7 조 (회원 탈퇴 및 자격 상실)</h2>
      <ul>
        <li>
          1. 회원은 언제든지 홈페이지를 통해 회원 탈퇴를 요청할 수 있으며, 당사는 회원의 요청에 따라 조속히 회원 탈퇴에
          필요한 제반 절차를 수행합니다. (단, 회원이 탈퇴를 요청할 때에는 회사의 홈페이지에 등록된 회원의 모든 카드가
          등록 해지되는 것을 전제로 합니다.)
        </li>
        <li>
          2. 회원이 다음 각 호의 사유에 해당하는 경우, 당사는 당해 회원에 대한 통보로써 회원의 자격을 상실시킬 수
          있습니다. 단, ‘다’의 경우에는 포인트도의 통보 없이 당연히 자격이 상실됩니다.
          <ul>
            <li>가. 카드 등록 시에 허위의 내용을 등록한 경우</li>
            <li>
              나. “포인트” 또는 “e-쿠폰”을 부정 적립 또는 부정 사용하는 등 서비스를 부정한 방법 또는 목적으로 이용한
              경우
            </li>
            <li>다. 회원이 사망한 경우</li>
            <li>라. 다른 회원의 서비스 이용을 방해하거나 그 정보를 도용하는 경우</li>
            <li>마. 서비스 홈페이지를 이용하여 법령, 본 약관 또는 공서양속에 반하는 행위를 하는 경우</li>
            <li>바. 당사 또는 다른 회원의 명예를 훼손하거나 모욕하는 경우</li>
          </ul>
        </li>
      </ul>

      <h2>제 8 조 (카드의 이용 및 관리)</h2>
      <ul>
        <li>
          1. 회원이 회사 클럽아티제 카드 의 적립 “포인트”에 따른 혜택을 이용하고자 할 경우에는 반드시 클럽아티제 카드를
          제시하는 것을 원칙으로 합니다. 단, 여기에서 “클럽아티제 카드를 제시한다”함은 클럽아티제 카드를 회사에 보여주는
          행위, 단말기에 읽히는 행위 등 클럽아티제 카드를 이용하고자 하는 고객이 회사로부터 적법하게 인정된 회원임을
          증명하는 행위를 말합니다.
        </li>
        <li>
          2. 회원이 서비스를 이용하고자 클럽아티제 카드를 제시할 경우 회사는 회원에게 본인 확인을 위한 신분증 제시를
          요청할 수 있습니다. 이 경우 회원은 회사의 요청을 준수하여야 정상적인 서비스를 제공받을 수 있습니다.
        </li>
      </ul>

      <h2>제 9 조 (충전)</h2>
      <ul>
        <li>
          1. 클럽아티제 카드의 충전은 다음 각 호의 방법으로 가능합니다.
          <ul>
            <li>
              가. 고객이 매장(일부 매장 제외)을 직접 방문하여 현금 또는 회사가 현금과 동일하게 인정하는 금전적
              가치(신용카드 등)를 제공하는 방법으로 해당 가치를 전자적 장치(단말기 등)를 이용하여 클럽아티제 카드에 직접
              저장하는 방법
            </li>
            <li>
              나. 회원이 회사와 약정한 절차에 따라 사전에 일정금액을 회사에 지급하고 회사는 지급 받은 금액에 해당하는
              가치를 클럽아티제 카드에 관한 정보를 기준으로 회사의 시스템에 전자적 방법으로 저장한 후, 회원과 회사가
              약정한 조건에 따라 포인트도의 절차 없이 회사가 지정한 전자적 장치 (단말기 등)를 통해 회사의 시스템에
              저장된 가치를 클럽아티제 카드로 이전하여 기록하는 방법(이하 “인터넷 결제를 통한 충전”이라고 합니다.)
            </li>
            <li>다. 기타 회사가 사전에 고지하고 고객이 해당 방법을 이용함으로써 동의 의사가 표시된 방법</li>
          </ul>
        </li>
        <li>
          2. 클럽아티제 카드에 충전 할 수 있는 최소 금액은 1만원이며, 1회 충전 시 1만원 단위로 충전할 수 있습니다. 다만,
          1장의 클럽아티제 카드에 저장된 총 금액은 50만원을 초과할 수 없습니다. 아울러 이와 같은 충전 금액 기준은 회사의
          마케팅 정책에 따라 변경될 수 있습니다.
        </li>
        <li>3. 클럽아티제 카드에 충전된 금액에 대하여는 이자가 발생되지 않습니다.</li>
        <li>
          4. 다음 각호에 해당되는 경우 회사는 고객의 충전을 제한할 수 있습니다.
          <ul>
            <li>가. 회사가 아닌, 타 국가의 클럽아티제 카드를 소지한 경우</li>
            <li>나. 기타 충전이 불가능한 기술적, 제도적 사유가 발생한 경우</li>
          </ul>
        </li>
        <li>
          5. 클럽아티제 카드에 저장된 금액을 다른 클럽아티제 카드에 저장된 금액과 합산할 수 없습니다. 단, 고객이
          고장카드에 대한 교체신청을 하거나, 회원이 분실신청을 하는 불가피한 사유가 발생한 경우에만 회사가 정한 절차에
          따라 제한적으로 가능합니다.
        </li>
        <li>
          6. 회원은 제3자에게 클럽아티제 카드를 충전하여 선물하기 기능 등을 통해서 양도할 수 있습니다. 단, 이 때
          제3자에게 양도한 카드는 거래가 없는 경우 당일에 한해서만 양도행위에 대한 취소가 가능합니다. 선물받은 카드를
          7일 이내 등록하지 않으면 자동 회수되며, 회수된 날로 24시간 이내 취소가 가능합니다. 이와 관련된 구체적인
          지침이나 정책은 회사의 마케팅 정책에 따라 달라질 수 있으며 사전에 홈페이지를 통하여 안내해 드립니다.
        </li>
      </ul>

      <h2>제 9 조의 2 (결제 확인 통지 • 충전신청 변경 및 취소)</h2>
      <ul>
        <li>
          1. 회사는 회원이 모바일 결제를 통한 충전을 신청한 경우 회원의 선택에 따라 이메일로 결제 확인통지를 합니다.
        </li>
        <li>
          2. 회사의 확인통지가 회원에게 도달한 시점에 계약이 성립한 것으로 보고, 그 통지에는 회원의 구매신청에 대한 확인
          및 판매가능여부, 구매신청의 정정 취소절차 등에 관한 정보 등을 포함하도록 합니다.
        </li>
        <li>
          3. 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 확인통지를 받은 후 즉시 구매신청 변경 및
          취소를 요청할 수 있고, 회사는 특별한 사정이 없는 한 그 요청에 따라 처리하여야 합니다. 다만 이미 대금이 지급된
          경우에는 제10조 2 의 청약철회 등에 관한 규정에 따릅니다.
        </li>
      </ul>

      <h2>제 9 조의 3 (자동 재충전)</h2>
      <p>
        클럽아티제 회원등록이 되어있는 클럽아티제 카드의 경우, 아래와 같은 방법으로 자동 재충전을 설정 할 수 있습니다.
      </p>
      <ul>
        <li>
          1. 기준 하한 자동 재충전 : 회원이 지정한 카드와 최저 잔액이하로 잔액이 하락하는 경우, 회원이 사전에 선택한
          결제 수단으로 지정한 금액이 재충전 됩니다.
        </li>
        <li>
          2. 월 정액 자동 재충전 : 회원이 지정한 특정 일자에 사전에 선택한 결제 수단으로 지정한 금액인 재충전 됩니다.
        </li>
      </ul>

      <h2>제 10 조 (환급)</h2>
      <ul>
        <li>
          1. 고객이 클럽아티제 카드에 충전된 잔액의 환급을 요청하는 때에는 회사는 클럽아티제 카드의 상태에 따라 다음
          각호에 명시된 절차에 의거하여 환급 처리를 합니다.
          <ul>
            <li>
              가. 정상카드에 대한 잔액은 가까운 아티제 매장에서 환급 받으실 수 있습니다. (단. 최종 충전 시 잔액의 40%
              이하의 경우에만 가능합니다.)
            </li>
            <li>
              나. 분실 신고된 클럽아티제 카드는 잔액 확인 등의 절차를 거쳐 분실카드의 잔액이 충전된 신규 클럽아티제
              카드를 발급하여 드립니다.
              <ul>
                <li>- 회원은 홈페이지에서 분실 신고를 한 후 해지를 할 수 없습니다.</li>
                <li>- 분실 신고한 카드의 잔액은 홈페이지에 등록된 다른 카드로 이체할 수 있습니다.</li>
                <li>
                  - 클럽아티제 카드 중 artisienne 회원에게 발급되는 아티지엔카드의 분실 재발급의 경우 연 2회까지만 무상
                  발급되며, 3회이상 재발급 신청은 불가합니다. 다만, 회원이 다시 artisienne Level이 되는 경우에는
                  연2회까지 재발급 신청을 할 수 있습니다.
                </li>
              </ul>
            </li>
            <li>
              다. 미사용된 정상카드에 대한 충전을 취소하고자 하는 경우에는 해당 거래의 영수증을 지참하여 매장에 충전
              취소를 요청함으로써 충전방법에 따라 현금 또는 신용카드 취소 등의 형식으로 환급해 드립니다.
            </li>
            <li>
              라. 고장카드는 매장에 접수하시면 잔액 확인 등의 절차를 거쳐 고장카드의 잔액이 충전된 신규 클럽아티제
              카드를 발급하여 드리며, 신규로 발급 받으신 후에는 고장카드를 사용하실 수 없습니다.
              <ul>
                <li>
                  - 등록된 카드의 교환 처리 후, 신규로 교환 받은 카드는 홈페이지에 다시 등록을 한 후 클럽아티제 혜택을
                  받을 수 있습니다.
                </li>
                <li>- 아티지엔카드를 고장카드 교환 받을 경우, 매장에 반납하셔야 합니다.</li>
              </ul>
            </li>
            <li>
              마. 고장카드 중 카드 번호의 식별 불가능한 경우와 분실 신고된 카드는 신규 클럽아티제 카드로의 발급 또는
              환불이 불가능합니다.
            </li>
          </ul>
        </li>
        <li>
          2. 클럽아티제 카드의 발행 목적 또는 기술적 사유(시스템 점검, 단말기 고장, 통신회선 불량, 신규 카드 또는
          단말기의 안정화 작업 등)로 일부 클럽아티제 카드는 환급이 제한될 수 있으며, 이 경우 회사는 포인트도의 환급 절차
          및 방법을 매장에서 배포하는 안내장 또는 홈페이지 등을 이용하여 고객에게 고지합니다.
        </li>
      </ul>

      <h2>제 10 조의 2 (청약철회 등)</h2>
      <ul>
        <li>
          1. 모바일 결제를 통한 충전을 청약한 회원은 수신확인의 통지를 받은 날부터 7일 이내에는 그 청약의 철회를 할 수
          있습니다.
        </li>
        <li>
          2. 회사는 제1항의 청약철회를 수신한 날부터 3영업일 이내에 승인취소 또는 승인취소가 불가할 경우 결제 대금을
          환급합니다. 결제 대금 환급의 경우 그 환급을 지연하는 때에는 그 지연기간에 대하여 전자상거래 등에서의
          소비자보호에 관한 법률 시행령이 정하는 지연이자율(연 100분의 24)을 곱하여 산정한 지연이자를 지급합니다.
        </li>
        <li>
          3. 지불수단 별 승인취소는 다음과 같은 단계로 이루어집니다.
          <ul>
            <li>
              1) 신용카드 : 결제대금 확정 시점 전 취소는 결제대금으로 청구 되지 않으며, 결제대금 확정 시점 후 취소는
              해당 청구월 익월 결제 대금에서 차감 됩니다.
            </li>
            <li>
              2) 휴대폰 : 당월 취소만 가능하며, 결제 대금에 청구 되지 않습니다. 익월 취소 요청 시 환불로 진행 됩니다.
            </li>
          </ul>
        </li>
        <li>
          4. 회사는 위 대금을 반환함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한
          때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록
          요청합니다.
        </li>
        <li>
          5. 회사의 시스템에 저장된 가치가 이미 클럽아티제 카드로 이전하여 기록된 경우에는 제10조의 규정에 의하여
          환급합니다.
        </li>
      </ul>

      <h2>제 11 조 (“포인트” 적립 및 등급포인트 혜택 • 스탬프 혜택)</h2>
      <ul>
        <li>
          1. “포인트” 적립
          <br />
          상품을 구입한 회원은 회사가 정한 아래와 같은 적립 기준에 따라 “포인트”를 부여 받게 됩니다. 클럽아티제 카드로
          회사가 정한 기준에 따라 결제 시 결제 금액의 3% 또는 5%의 포인트가 적립됩니다.
          <ul>
            <li>
              가. 동일 계정에 등록된 클럽아티제 카드로 연속하여 결제가 이루어지는 경우에도 거래 즉시 포인트가
              적립됩니다.
            </li>
            <li>나. 적립된 포인트에 따라 회원의 등급이 나누어 지며, 등급별로 다른 혜택이 적용됩니다.</li>
            <li>다. 모든 쿠폰은 회사에서 발급 시 정해진 유효기간 내에서만 사용이 가능합니다.</li>
          </ul>
        </li>
        <li>
          2. 회원 등급포인트 혜택 및 유효기간
          <br />
          적립된 포인트에 따라 등급이 나뉘며, 등급포인트로 다양한 혜택을 제공해드립니다. 각 상위 등급은 하위 등급의
          혜택을 포함합니다. 단, 각 등급포인트 혜택은 회사의 마케팅 정책에 따라 달라질 수 있습니다. 이 경우 회사는 제
          3조 제2호에 따라 1개월 전에 고지합니다.
        </li>
        <li>
          3. 스탬프의 기능 및 혜택
          <br />
          회사에서 스탬프 프로모션이 진행되는 기간 동안 회원이 등록된 클럽아티제 카드로 결제 하는 경우, 회원 가입 시
          자동으로 발급된 스탬프계정에 회사에서 정한 적립 기준에 따라 “스탬프”가 적립됩니다. 아울러 스탬프의 구체적인
          운영 방침이나 활용 방법 및 혜택은 회사의 마케팅 정책에 따라 변할 수 있으며, 이에 대해서는 별도의 방법으로
          사전에 안내해 드립니다.
        </li>
      </ul>

      <h2>제 12 조 (“포인트” 유효기간 및 소멸)</h2>
      <ul>
        <li>1. 포인트의 유효기간은 적립 일로부터 1년 (12개월) 입니다.</li>
        <li>
          2. 유효기간이 경과된 포인트는 적립 일 기준으로 12개월 후부터 월 단위 선입선출 방식에 의하여 자동으로 소멸되며,
          클럽아티제 앱 > 포인트 > 소멸예정 포인트를 통해 확인 가능합니다.
        </li>
        <li>3. 클럽아티제 카드로 결제 시 적립된 포인트는 해당 결제를 취소하실 경우 적립이 취소됩니다.</li>
      </ul>

      <h2>제 13 조 (고객의 의무)</h2>
      <ul>
        <li>
          1. 고객은 이 약관에서 규정하는 사항과 이용안내 또는 공지사항 등을 통하여 회사가 제공하는 사항을 준수하여야
          하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
        </li>
        <li>2. 회원은 회사가 제공한 포인트를 이용하여 영업 활동을 할 수 없습니다.</li>
        <li>
          3. 회원은 적립한 포인트를 다른 회원에게 재판매 할 수 없으며, 위반 시에는 제공받았던 혜택을 반환하여야 합니다.
        </li>
      </ul>

      <h2>제 14 조 (휴면계정 관리)</h2>
      <ul>
        <li>
          1. 회사는 회원이 홈페이지 또는 모바일APP에 12개월 이상 로그인 하지 않거나 계정에 등록된 아티제 카드 또는
          오프라인 매장에서 멤버쉽 조회 또는 거래(최초 충전, 재충전, 결제 등 모든 거래 포함)가 12개월 이상 없는 경우,
          휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 아래와 같이 제한/상실시킬 수 있습니다.
        </li>
        <li>
          2. 12개월 이상 서비스에 로그인하지 않고 등록된 아티제 카드의 거래내역이 없는 경우에는 휴면계정으로 별도
          관리되어 홈페이지 이용이 중단되고, 이후 다시 이용하시려면웹 또는 앱 로그인 시 휴대폰 본인인증 또는 휴대폰 점유
          인증 후 해제 하시거나, 관리자센터를 통한 휴면 회원 조회 후 휴면해제로만 가능합니다. 또한, 휴면계정으로 별도
          관리되는 경우 아티제 포인트와 등급별 쿠폰이 제공되지 않으며, 등급별 유지 조건 미충족 시 회원 등급 레벨이 하향
          조정될 수 있습니다.
        </li>
      </ul>

      <h2>제 15 조 (회원 탈퇴와 포인트의 소멸 처리)</h2>
      <ul>
        <li>
          1. 본 약관 제 7 조 제 1 항에 정해진 방법으로 회원 탈퇴 시 회원 탈퇴 요청일 현재까지 적립된 포인트는 자동으로
          소멸되며, 재가입 시 소멸된 포인트는 복원되지 않습니다.
        </li>
        <li>
          2. 본 약관 제 7 조 제 2 항에 따라 회원 자격이 상실된 경우, 자격 상실일 현재까지 적립된 포인트는 자동으로
          소멸됩니다.
        </li>
      </ul>

      <h2>제 16 조 (거래지시의 철회)</h2>
      <p>
        고객이 클럽아티제 카드로 서비스를 제공받거나 제품 또는 상품을 구매하고 클럽아티제 카드의 잔액이 차감되어
        거래내역이 기록된 후에는 그 거래가 발생한 날의 7일 이내에 영수증 등 구매사실을 소명할 수 있는 자료를 첨부하여
        환불을 요구하는 경우, 거래를 취소하거나, 클럽아티제 카드 에 재충전해 드립니다. 또한 프로모션 기간 내 지급된
        쿠폰, 또는 판촉물 등을 증정 받으신 경우 이를 반드시 함께 반환하셔야만 거래지시의 철회가 가능합니다.
      </p>

      <h1>제 3 장 거래내역 정보</h1>
      <h2>제 17 조 (거래내역의 수집)</h2>
      <ul>
        <li>
          1. 회사는 고객이 클럽아티제 카드를 통해 서비스 이용 및 제품 또는 상품 구매 시 필요한 최소한의 거래내역
          정보(카드 번호, 거래 일시, 거래 금액, 단말기 및 매장 정보 등)를 수집합니다.
        </li>
        <li>
          2. 고객의 계속적인 클럽아티제 카드사용은 클럽아티제 카드를 통해 수집된 거래내역 정보가 이용대금 정산의 목적 및
          회사의 마케팅 활용의 목적에 사용되는 것에 대하여 동의한 것으로 간주합니다.
        </li>
      </ul>

      <h2>제 18 조 (거래내역의 제공)</h2>
      <ul>
        <li>1. 회원은 홈페이지 및 모바일 앱을 통해 거래 내역을 확인할 수 있습니다.</li>
        <li>
          2. 고객이 거래내역을 서면으로 받고자 하는 경우, 클럽아티제 카드를 소지한 본인임을 확인하기 위해 필요한 자료
          (클럽아티제 카드 사본, 신분증 사본 등 회사가 요청하는 자료)를 거래내역 신청서와 함께 팩스 또는 우편으로 회사에
          송부하여야 하며, 회사는 클럽아티제 카드 소지자 본인임이 확인되는 경우에 한하여 요청을 받은 날로부터 2주 이내에
          해당 정보를 고객이 요청한 수령지로 팩스 또는 우편을 이용하여 제공합니다.
          <ul>
            <li>- 우편주소 : 서울특별시 강남구 테헤란로 28길 32 ㈜ 보나비 3층 마케팅팀</li>
            <li>- 대표번호 : 02-2155-5777</li>
            <li>- 팩스 : 02-2155-5771</li>
            <li>
              고객이 본 조에 의거 거래내역을 제공받기 위하여 회사에 제공한 수령지 정보가 부정확하거나 오류인 관계로
              발생된 고객의 해당정보 누출 또는 고객의 손해에 대하여 회사는 책임을 지지 않습니다.
            </li>
          </ul>
        </li>
      </ul>

      <h2>제 19 조 (거래내역의 정정)</h2>
      <ul>
        <li>
          1. 고객은 본인이 이용한 거래와 관련된 오류를 발견하는 경우 소명할 수 있는 자료를 갖추고 회사가 정한 절차를
          거쳐, 해당 거래에 대한 정정을 요청할 수 있습니다.
        </li>
        <li>
          2. 회사는 본 조 제1항에 의해 고객의 정정 요청을 받은 날로부터 2주 이내에 해당 거래내역을 검토하여 고객에게 그
          결과를 통보하여 드립니다.
        </li>
      </ul>

      <h1>제 4 장 개인정보 보호</h1>
      <h2>제 20 조 (개인정보 의 취급위탁)</h2>
      <ul>
        <li>
          1. 회사는 클럽아티제 서비스 제공을 위해 아래와 같이 개인정보를 위탁하고 있으며, 위탁계약 시 개인정보가
          안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은
          아래와 같습니다.
          <ul>
            <li>
              가. 수탁업체 및 위탁업무 내용
              <table border="0" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <td>수탁업체</td>
                    <td>위탁업무 내용</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>㈜엔에이치엔페이코</td>
                    <td>
                      개인정보의 보관 및 유지
                      <br />
                      ㈜보나비의 요청에 의한 공지사항/마케팅정보전송
                      <br />
                      (Push / LMS / SMS / E-mail)
                      <br />
                      시스템 장애 관련 민원 대응 및 처리
                    </td>
                  </tr>
                  <tr>
                    <td>엔에이치엔한국사이버결제㈜</td>
                    <td>본인확인</td>
                  </tr>
                  <tr>
                    <td>NHN KCP Corp.</td>
                    <td>전자지불서비스</td>
                  </tr>
                  <tr>
                    <td>㈜씨에스쉐어링</td>
                    <td>고객상담센터 위탁운영</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              나. 개인정보의 보유 및 이용기간: 회원 탈퇴시 또는 위탁계약 종료 시 제1항에 의해 고객의 정정 요청을 받은
              날로부터 2주 이내에 해당 거래내역을 검토하여 고객에게 그 결과를 통보하여 드립니다.
            </li>
          </ul>
        </li>
      </ul>

      <h2>제 21 조 (개인 정보 보호)</h2>
      <ul>
        <li>
          1. 회사는 고객의 정보 수집 시 정상적인 서비스를 위한 최소한의 정보를 수집하며, 고객의 개인식별이 가능한
          개인정보를 수집하는 때에는 반드시 해당 고객의 동의를 받습니다.
        </li>
        <li>
          2. 회사는 양질의 서비스를 제공하기 위해 여러 비즈니스 파트너와 제휴를 맺거나 국가 기관의 요구 등을 위해
          개인정보를 위탁관리 하거나 개인정보를 제공할 수 있습니다. 그럴 경우 회사는 홈페이지를 통하여 해당 목적, 내용
          및 업체 명을 밝혀 고객의 동의를 받습니다. 단, 고객의 소득공제 목적으로 국세청에 개인정보 및 클럽아티제 카드
          사용내역을 제공하는 경우 등과 같이 고객의 요청에 의하는 경우는 예외로 합니다.
        </li>
        <li>
          3. 고객이 제공한 개인정보는 고객의 동의 없이 제 3자에게 누설하거나 제공하지 않습니다. 다만, 다음 각 호에
          해당하는 경우에는 예외로 합니다.
          <ul>
            <li>가. 배송을 위해 배송담당 업체 등에게 배송에 필요한 최소한의 고객 정보를 알려주는 경우</li>
            <li>나. 통계 작성, 학술 연구 또는 시장 조사를 위해 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
            <li>다. 관계 법령에 의하여 수사상의 목적으로 관계 기관으로부터 요구 받은 경우</li>
            <li>라. 서비스의 이용에 따른 거래상 발생하는 요금정산</li>
            <li>마. 서비스의 제공에 필요한 범위 내에서 제휴 회사와 공유해야 하는 경우</li>
            <li>바. 기타 관계 법령에 의한 경우</li>
          </ul>
        </li>
        <li>
          4. 고객은 자신의 개인정보처리와 관련하여 개인정보의 처리에 관한 정보를 제공받을 권리, 개인정보에 대하여 열람을
          요구할 권리, 개인정보의 처리정지, 정정, 삭제 및 파기를 요구할 권리를 갖습니다.
        </li>
        <li>
          5. 회사 또는 회사로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는
          당해 개인정보를 지체없이 파기하여야 합니다.
        </li>
      </ul>

      <h1>제 5 장 기타</h1>
      <h2>제 22 조 (책임 소재)</h2>
      <ul>
        <li>
          1. 회사는 고객이 클럽아티제 카드 서비스 이용 중 발생한 손해에 대하여 배상 할 책임을 집니다. 단, 본 약관에
          포인트도 명시된 경우 및 다음 각호의 ‘가’에 해당하는 경우는 제외합니다.
          <ul>
            <li>
              가. 클럽아티제 카드를 회사가 클럽아티제 카드 후면 또는 단말기를 통해 안내하거나 규정하는 방법 이외의
              방법으로 거래 행위를 한 경우(단, 여기에서 “거래 행위”라 함은 카드 번호를 입력하는 행위, 카드를 단말기에
              접촉하는 행위 등 고객이 클럽아티제 카드서비스를 이용하기 위해 클럽아티제 카드를 이용하는 행위를 말합니다.)
            </li>
            <li>나. 클럽아티제 카드를 회사가 지정하지 아니한 방법으로 불법 거래 또는 부정 사용한 경우</li>
            <li>다. 클럽아티제 카드를 변형하거나 원형을 훼손하여 사용한 경우</li>
            <li>라. 클럽아티제 카드를 도난 당하거나 분실한 경우</li>
            <li>
              마. 제3자가 권한 없이 고객의 클럽아티제 카드를 이용할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고
              고객이 자신의 클럽아티제 카드 정보 (카드 번호 및 PIN 번호 포함)를 누설 또는 노출하거나 방치한 경우
            </li>
          </ul>
        </li>
        <li>
          2. 고객이 본 조 제1항에서 규정한 행위를 하여 회사에 손해가 발생하는 경우, 그 고객은 회사의 손해에 대하여
          배상할 책임을 집니다.
        </li>
      </ul>

      <h2>제 23 조 (분쟁 해결)</h2>
      <ul>
        <li>
          1. 회사는 고객이 제기하는 정당한 의견이나 불만을 반영하기 위하여 고객의견수렴 제도를 운영하며, 고객은
          홈페이지의 해당 서비스 페이지를 통해 의견을 제시할 수 있습니다.
          <ul>
            <li>- 홈페이지 주소 : www.cafeartisee.com</li>
          </ul>
        </li>
        <li>
          2. 회사는 고객으로부터 제출되는 불만사항 및 의견은 15일 이내에 그 사항을 처리합니다. 다만, 신속한 처리가
          곤란한 경우에는 고객에게 그 사유와 처리일정을 즉시 통보합니다.
        </li>
        <li>
          3. 회사와 고객간에 발생한 분쟁이 원만히 해결되지 않는 경우, 관련 법령에 따라 ‘소비자보호법’에 따른
          한국소비자원의 소비자분쟁조정위원회를 통해 조정신청이 가능합니다.
        </li>
      </ul>

      <h2>제 24 조 (관할 법원)</h2>
      <p>
        클럽아티제 서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 회사의 본점 소재지를 관할하는 법원 또는 민사소송법상의
        관할 법원을 제1심 관할 법원으로 합니다.
      </p>

      <h2>(부칙)</h2>
      <p>본 이용 약관은 2019년 1월 1일부터 시행합니다.</p>
    </div>
  </div>
);
